.subservices-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px !important;
}

.subserviceh {
    font-family: Lato;
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(9, 81, 130, 1);
    margin-bottom: 50px;
    margin-top: 24px;

}

.subservice-div {
    width: 100%;
    gap: 60px;
    border: 2px solid #F0F0F0;
    border-radius: 12px;
    background-color: #ffffff;
    padding-bottom: 50px;
}

.innerdiv {
    padding: 10px;
}

.subserviceimg {
    width: 20%;
    padding-top: 50px;
}

.R1-ss {
    width: 85%;
    display: flex;
    flex-direction: row;
    gap: 34px;
}

.Img-heading {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
    margin-top: 30px;
    padding-bottom: 10px;

}


@media screen and (max-width: 575px) {

    /* .subserviceimg{
        width: 50%;
    } */
    .R1-ss {
        flex-wrap: wrap;
    }

    .subservices-main-div {
        margin-top: 60px;
    }

    .subserviceh {
        /* font-size: 22px; */
    }

    .Img-heading {
        margin-top: 10px;
        font-size: 16px;
    }

    /* .subserviceimg{
        width: 6vw;
    } */
    .subservice-div{
        width: 80% ;
    }

}

@media screen and (min-width: 575px) and (max-width: 768px) {
    .R1-ss{
        display: flex;
        flex-wrap: wrap;
        width: 104vw;
    }
    .subservice-div{
        width: 40% ;
    }
}