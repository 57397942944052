/* Reactplayer css */
.case-header-player {
  height: 80vh !important;
  width: 70vw !important;
}

@media screen and (max-width: 1024px) {
  .case-header-player {
    height: 63vh !important;
    width: 90vw !important;
  }
}
@media screen and (max-width: 850px) {
  .case-header-player {
    height: 52vh !important;
  }
}
@media screen and (max-width: 768px) {
  .case-header-player {
    height: 47vh !important;
  }
}
@media screen and (max-width: 596px) {
  .case-header-player {
    height: 37vh !important;
  }
}
@media screen and (max-width: 425px) {
  .case-header-player {
    height: 27vh !important;
  }
}
/* Reactplayer css end*/

.casestudy-bg {
  background: #c7eefb;
  position: relative; /* Make this relative so circles are positioned relative to it */
  z-index: 1;
  padding-bottom: 50px; /* Adjust padding dynamically if needed */
}

.Top-div {
  position: absolute;
  width: 80px; /* You can use vw/vh if you want dynamic sizing */
  height: 80px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #d1d5db;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Floating Circles Positioning */




/* Responsive Adjustments */
@media screen and (max-width: 1024px) {
  .Top-div {
    width: 70px;
    height: 70px;
  }
  .Top-div-right {
    top: 15%;
  }
  .Top-div-center {
    top: 40%;
  }
  .Top-div-left {
    top: 60%;
  }
}

@media screen and (max-width: 768px) {
  .Top-div {
    width: 60px;
    height: 60px;
  }
  .Top-div-right {
    top: 20%;
  }
  .Top-div-center {
    top: 45%;
  }
  .Top-div-left {
    top: 70%;
  }
}

@media screen and (max-width: 480px) {
  .Top-div {
    width: 50px;
    height: 50px;
  }
  .Top-div-right {
    top: 25%;
  }
  .Top-div-center {
    top: 50%;
  }
  .Top-div-left {
    top: 75%;
  }
}





.mainheading h2 {
  color: #005082;
}
.maincontentwrappper {
  border-right: 1px solid grey;
  padding-right: 15px;
}

.logocontainer img {
  padding: 20px 0 50px 0;
}
.thechallenge h5 {
  font-weight: 700;
  font-size: 20px;
}
.problem-para p,
.thechallenge ul li {
  max-width: 95%;
  font-size: 18px;
}
.company-info p {
  font-size: 18px;
}

.companysector p span {
  font-weight: 700;
}
.companysector p {
  font-size: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.45);
  margin: 0;
  padding: 7px 10px;
}
.companysector a {
  font-size: 16px;
  text-decoration: none;
  color: #0073b1;
  font-weight: 700;
}
.companysector p:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}

.companysector p:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.companysector-casestudy:hover {
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 4px 6px rgb(0 0 0 / 20%);
}
.companysector-casestudy {
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  background: #fff;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 15px;
}

.customer-feedback-wrapper .feedback-container p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  max-width: 90%;
  margin: auto;
}
.image-container img {
  border-radius: 50%;
}
.image-container {
  border-radius: 50%;
  max-width: 100px;
  margin: auto;
  padding-top: 50px;
}
.customer-information {
  text-align: center;
}
.customer-information h2 {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding-top: 25px;
  font-size: 22px;
}
.customer-information h4 {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}
.caseheader-wrapper-text h1 {
  color: #005082;
  font-size: 45px;
  font-weight: 700;
  line-height: 1.5;
}
.caseheader-wrapper-text p {
  font-size: 18px;
  font-weight: 500;
}
