
.CaseStudyTopCard_Container{
    width: 100%;
    padding: 60px 0;
}
.CaseStudyTopCard_Content_Container{
    row-gap: 30px;
}
.CaseStudyTopCard_Content{
    border-radius: 45px;
    box-shadow: 0px 3px 6px 1px #53afdd;
    /* margin-top: 10px; */
    transition: 0.3s ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CaseStudyTopCard_Content:hover{
    background-color: rgb(191, 235, 250, 0.3);
    cursor: pointer;
    transition: 0.1s ease-in-out;
}
.CaseStudyTopCard_Company_Logo{
    height: 100px !important;
    /* min-height: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.CaseStudyTopCard_Company_Logo img{
    max-height: 100% !important;
}
.pagination{
    list-style-type: none;
}
.page-item.active{
    background: red ;
    }

