.title {
  color: #004299;
  font-size: 44px;
}
.para {
  color: #f1f1f1;
}
.btn-empathy{
  display: flex;
height: 50px;
}

#btn1 {
  cursor: pointer;
  color: #fff!important;
  /* border: 2px solid #005082; */
  border: 0;
padding: 7.5px;
transition:box-shadow 0.5s ease!important;
}
#btn1:hover {
  color: #005082!important;
  background: white !important;
  border: 2px solid #005082;
  box-shadow: 5px 5px 5px 0px #005082;
  font-weight: 600;
}
.contentRow {
  margin-right: 15px !important;
}

.lang {
  letter-spacing: 1px;
}
.lang-input {
  width: 75%;
}
.select-lang {
  margin-top: 0.2em;
  padding: 5px;
  border-radius: 2px;
  background: #fff;
  color: #0071e0;
  border: 1px solid #0071e0;
  letter-spacing: 1px;
}
.choice {
  padding: 8px !important;
  font-size: 12px;
  width: 100%;
  height: 45px;
  font-family: inherit;
  line-height: inherit;
}
/*****************/
#box2,
#box3 {
  display: none;
}

#myInput1,
#myInput2,
#myInput3 {
  width: 75%;
  padding: 0.5em;
  display: none;
  /* position: relative;
    z-index: 1000; */
}
/****************/
 .btn {
  background: linear-gradient(
    90deg,
    rgba(191, 235, 250, 1) 0%,
    rgba(159, 209, 230, 1) 0%,
    rgba(107, 166, 197, 1) 0%,
    rgba(77, 142, 178, 1) 0%,
    rgba(0, 80, 130, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  color: #fff;
  outline: none;
}
#analyzeBtn{
  display: none;
}
.emojiRow {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.emoji {
  opacity: 0.15;
  vertical-align: middle;
  border: 0;
}
#imgopa{
  opacity: 1.0;
}
.emoji-show {
    opacity: 1.0;
    vertical-align: middle;
    border: 0;
  }

.footer {
  padding: 10px 10px;
  margin: 25px 0 10px;
  background: #f1f1f1;
}
.footerText {
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #1c7e14;
}
.footerLink {
  color: #3f444c;
  text-decoration: none;
  font-family: sans-serif;
}
#loading {
  width: 2rem;
  height: 2rem;
  border: 5px solid #f3f3f3;
  border-top: 6px solid #9c41f2;
  border-radius: 100%;
  margin: auto;
  visibility: hidden;
  animation: spin 1s infinite linear;
}
#loading.display {
  visibility: visible;
}
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

#loader_empthy{
      display: none;
      position: fixed;
      top: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
}
.wrapper{
  position: absolute;
  top: -35px;
  transform: scale(1.5);
}
.loader{
  height: 25px;
 width: 1px;
 position: absolute;
 animation: rotate 3.5s linear infinite;
}
.loader .dot{
  top: 30px;
 height: 7px;
 width: 7px;
 background:#0071e0;
 border-radius: 50%;
 position: relative;
}
.text{
  position: absolute;
  bottom: -85px;
  font-size: 25px;
  font-weight: 400;
  font-family: 'Poppins',sans-serif;
  /* color: #fff; */
}
@keyframes rotate {
  30%{
    transform: rotate(220deg);
  }
  40%{
  transform: rotate(450deg);
    opacity: 1;
 }
 75%{
  transform: rotate(720deg);
  opacity: 1;
 }
 76%{
  opacity: 0;
 }
 100%{
  opacity: 0;
  transform: rotate(0deg);
 }
}
.loader:nth-child(1){
  animation-delay: 0.15s;
}
.loader:nth-child(2){
  animation-delay: 0.3s;
}
.loader:nth-child(3){
  animation-delay: 0.45s;
}
.loader:nth-child(4){
  animation-delay: 0.6s;
}
.loader:nth-child(5){
  animation-delay: 0.75s;
}
.loader:nth-child(6){
  animation-delay: 0.9s;
}