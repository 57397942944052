.saas-useCase-card {
  margin-right: 0px;
  margin-left: 0px;
  flex-wrap: wrap;
  margin-bottom: 36px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 36px;
}

.ProblemSolving-Person-img{
  /* object-fit: contain; */
} 

.saas-useCase-card-div {
  width: 31%;
  border: 1px solid #f0f0f0;
  padding: 28px 21px;
  background-color: white;
}

.saas-useCase-card-div:hover {
  color: white;
  background-color: #03a6ca;
}

.saas-useCase-card-div-img {
  margin-bottom: 12px;
}

.usecases-section-saas {
  margin-top: 100px !important;
  margin-bottom: 68px !important;
  /* justify-content: center; */
}


.usecases-section-saas-head {
  font-family: Lato;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: #095182;
}

.usecases-section-saas-para {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin-bottom: 6px;
  /* text-align: center; */
}

.usecases-section-saas-title-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 0 56px 0;
}

.saas-useCase-card-para {
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

/* ========================== Industries=========================== */

.industries-section-saas {
  background-color: rgb(225, 241, 250);
  padding-top: 55px;
  /* padding-bottom: 125px; */
}

.industries-section-saas-main-div-special {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 71px;
  padding: 0 70px;
}

.industries-section-saas-main-div-parent {
  width: 60% !important;
  gap: 0px;
  /* background-color: red;  */
 display: flex;
  justify-content: center;
  flex-direction: column; 

}

.industries-section-saas-child-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  /* width: 25%; */
  /* background-color: red; */

}

.industries-section-content {
  /* border-radius: 156px; */
  /* padding-top: 30px; */
  border-radius: 50%;
  /* padding: 36px; */
  border: 1px solid #F0F0F0;
  background: #FFF;
  box-shadow: 0px 4px 0px 0px #095182;

  width: 175px;
  height: 175px;
  position: relative;
}

.industries-section-saas-child-div-img {
  /* height: 45%; */
  width: 25%;
}

.industries-section-saas-para {
  margin-top: 16px;
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  /* text-align: center; */
}

.industries-section-saas-img {
  /* background-color: rgb(225, 241, 250);
  padding-top: 40px;
  height: 160px */
}

.industries-section-saas-p {
  /* margin-top: 18px; */
  /* font-family: Lato; */
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  margin-top: 11px;
  width: 149px;

  padding: 0 5px;
  /* padding-bottom: 30px; */
}

.Execution-section-saas-main-div {
  row-gap: 80px;
}

.industries-section-saas-id {
  position: absolute;
  top: 6px;
  left: 6px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  /* fill: var(--high, #095182); */
  background-color: #095182;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}



/* ================== media-queries for upto 767px ======================== */
@media screen and (max-width: 768px) {
  .saas-useCase-card-div {
    width: 75%;
  }

  .industries-section-saas-img {
    height: 20%
  }

  .industries-section-saas-main-div-parent {
    width: 80% !important;
  }

}

/* ================== media-queries for upto 375px ======================== */
@media screen and (max-width: 375px) {
  /* .saas-useCase-card-div {
    width: 85%;
  }

  .industries-section-saas-child-div {
    width: 40%;
  }

  .industries-section-saas-main-div-parent {
    width: 80% !important;
  }

  .industries-section-saas-main-div {
    margin-bottom: 0px;
  }

  .industries-section-saas-img {
    height: 20%
  } */
}