@import "rsuite/dist/rsuite.css";
.navbar {
  /* position: absolute; */
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent !important;
}
.navbar-brand {
  width: 180px;
}
.navbar-collapse {
 /* justify-content: end; */
 justify-content: space-between;
 margin-left: 10vw;
}
.navbar-nav .nav-link {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px!important;
}
.dropdown-title-link {
  color: #000 !important;
  font-weight: 500;
  font-size: 18px!important;
}

/* .dropdown{
  border-bottom: 2px solid transparent;
}
.dropdown:hover{

  border-bottom: 2px solid #0c6ca8;
} */
.dropdown-menu {
  border: 0;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  /* width: 60%; */
  /* list-style: none; */
  justify-content: flex-end;
  align-items:center ;

}
.navbar-nav .nav-item {
  margin: 0 5px ;
}


/**********************/
.nav-item-link{
  position: absolute;
  right: 275px;
}

.navbar-nav .nav-link:hover {
  color: #005082;
  border-bottom: 2px solid #0c6ca8;
}
.contact_us_btn_header {
  display: flex;
  /* justify-content: space-between; */
  justify-content: end;
  align-items: center;
  min-width: 28%;
  /* margin-left: auto; */
  color: #005082!important;
}

.header-wrap {
  background-color: #ffffff99;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 1px 1px 10px 1px #00000010;
}
.letsTalk {
  justify-content: space-around;
}

.hamburger-menu {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 36px;
  width: 33px;
  cursor: pointer;
}
.bar-top,
.bar-middle,
.bar-bottom {
  height: 4px;
  background: #000;
  border-radius: 5px;
  margin: 4px 0;
  transform-origin: left;
  transition: all 0.5s;
}

.navbar-collapse.collapse.show .navbar-nav {
  margin-top: 16px;
}
.navbar-collapse.collapse.show .navbar-nav li {
  border-bottom: 1px solid #f0f0f0;
}
/* .navbar-collapse.collapse.show .contact_us_btn_header {
  margin: 16px 0;
  align-items: flex-start;
  flex-direction: column;
} */
.navbar-collapse.collapse.show .contact_us_btn_header .btn {
  margin: 10px 0;
}
.btn.blogs:hover {
  color: #005082!important;
  box-shadow: 5px 5px 5px 0px #005082;
  background-color: transparent!important;
  transition: all 0.5s ease;
  border: 2px solid #005082;
  font-weight: 900;
}
.btn.blogs {
  color: #005082!important;
  border: 2px solid #005082 ;
  transition: all 0.5s ease;
  background: transparent!important;
  font-weight: 900;
}
.header-blogs-btn {
  color: #fff!important;
  border: 2px solid #005082;
padding: 7.5px;
transition:box-shadow 0.5s ease!important;
/* transition: color 0s ease; */
}
.btn.header-blogs-btn:hover {
  color: #005082!important;
  background: white !important;
  border: 2px solid #005082;
  box-shadow: 5px 5px 5px 0px #005082;
  font-weight: 600;
}

.letstalk-hidden {
  border: 0;
  /* background: transparent;
  position: absolute;
  width: fit-content;
  color: transparent !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
   */
}

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
  .dropdown-item {
    font-size: 16px;
  }
  .header-wrap .dropdown-menu.show {
    background: white;
    display: absolute !important;
  }
   .navbar-nav{
    width: 60%;
  }
}


@media (max-width: 1199.98px) {
  .navbar-dark .navbar-toggler {
    padding: 0;
  }
  .navbar-brand img {
    width: 150px;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 14px;
  }
}

/* dropdown css case studies */
.navbar-nav .dropdown-submenu:hover > ul.dropdown-menu {
  display: block;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  box-shadow: 0px 0px 3px 1px rgb(228, 227, 227);
}
.dropdown-menu {
  box-shadow: 0px 0px 3px 1px rgb(228, 227, 227);
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}
.dropdown-menu > li > a:hover {
  cursor: pointer;
}

/* dropdown css case studies end here */

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

/* .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
} */
.dropdown-toggle::after {
  width: 10px;
  vertical-align: 0.2rem;
}

/* .dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
} */

/* .dropdown.show {
  display: block;
} */

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  padding-right: 0px !important;
  padding: 0px !important;
  padding-bottom: 3px !important;
  line-height: 26px !important;
}
.rs-btn {
  font-size: unset !important;
  background-color: transparent !important;
  color: black !important;
}

.rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
  display: none !important;
}

.rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-item-toggle {
  padding: 5px 26px !important;
  font-size: 18px !important;
  color: #000 !important;
}

.rs-dropdown-item {
  padding: 0px !important;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background: transparent !important;
  text-decoration: none !important;
}
/* .dropup,
.dropright,
.dropdown,
.dropleft {
  display: unset !important;
} */

.navbar-nav .dropdown-menu {
  position: absolute !important;
}

@media only screen and (max-width: 1000px) {
  .rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-menu {
    top: 40px !important;
  }
  .rs-dropdown-item-submenu .rs-dropdown-menu {
    left: 0px !important;
  }
}

@media only screen and(max-width:992px) {
  .rs-btn {
    font-size: 14px !important;
  }
  .dropdown-item {
    display: absolute;
    bottom: 0px !important;
  }
  .dropdown-menu.show {
    display: unset !important;
  }
}

.nav-item-div{
  display: flex;
  justify-content: center;
}