.Footer-wrapper {
  background: linear-gradient(223.74deg, #bfebfa -7.23%, #ffffff 88.65%);
  /* padding: 144px 0px; */
  border-radius: 156px 156px 0px 0px;

  padding-top: 60px;
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.Footer-sub-wrapper-01 {
  /* align-items: center; */
  justify-content: space-between;
}
/* .facebooklogo {
  padding: 10px;
  
} */

.footer-let-talk-btn{
  color: #fff!important;
  /* border: 2px solid #005082; */
padding: 7.5px;
transition:box-shadow 0.5s ease!important;
}
.footer-let-talk-btn:hover {
  color: #005082!important;
  background: white !important;
  border: 2px solid #005082;
  box-shadow: 5px 5px 5px 0px #005082;
  font-weight: 600;
}
.mainbutton-footer{
  border: 0;
  padding-top: 4px;
}
.footer-items-0 p {
  color: #005082;
}

.footer .nav-item {
  padding: 1px 0px;
}

.footer-social-btn {
  /* border: 0; */
  /* border-radius: 50%; */
  background-color: #F8FDFE ;
  margin: 15px 8px;
  
  /* transition: 0.3s all ease-in-out; */
  /* color: black; */
  /* width: 40px; */
  height: 40px;
}
/* .footer-social-btn:hover {
  background-color: transparent !important;
  box-shadow: 5px 5px 5px 0px #005082;
  border: 1px solid #005082;
} */
/* .footer-social-btn img {
  width: 100%;
} */
.text-decoration-underline {
  text-decoration: none !important;
}

.footer-sub-items-0 a,
.nav-item a {
  color: rgba(0, 0, 0, 0.9);
  transition: 0.3s all ease-in-out;
  font-size: 16px;
}
.footer-sub-items-0 a,
.nav-item .case-link {
  /* color: #76848f; */
  color: rgba(0, 0, 0, 0.9);
  transition: 0.3s all ease-in-out;
  font-size: 16px;
}
.footer-sub-items-0:hover .nav-item a:hover {
  color: black;
}
.footer-sub-items-0:hover .nav-item .case-link:hover {
  color: black;
  text-decoration: underline !important;
}
.LakeComm-footer-Address,.LakeComm-footer-Address .heading-wrapper h6 {
  font-size: 18px;
  font-weight: 700;
  color: #005082;
  margin: 0;
  text-decoration: none;
}
.LakeComm-footer-Address,.LakeComm-footer-Address .heading-wrapper h6:hover {
  text-decoration: none;
  /* cursor: pointer; */
  cursor:default;
}
.LakeComm-footer-Address .heading-wrapper p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  /* cursor: pointer; */
  cursor: default;
  max-width: 90%;
  margin-bottom: 15px;
}
/* .footer-links:hover {
  color: black;
}
.footer-links {
  color: #76848f !important;
  transition: 0.3s all ease-in-out;
} */
.footer-img-wrapper {
  max-width: 260px;
}
.heading-wrapper .call-logo {
  max-width: 30px;
}
.call-logo a img:hover {
  box-shadow: 3px 3px 3px 2px #005082;
  border-radius: 50%;
}
.dropdown-item {
  cursor: pointer;
}
.C-dropdown-menu {
  position: absolute !important;
}
.c-nav-link-footer:hover {
  color: #000 !important;
  border-bottom: none !important;
  /* margin-top: 444px; */
}
.newStyleNav{
 display: block;
}
@media only screen and (max-width: 767px) {
  .lakeCom-service-01-0 {
    justify-content: center;
  }
}
@media only screen and (max-width: 576px) {
  .Footer-sub-wrapper-01 div {
    justify-content: center !important;
    text-align: center;
  }
  .footer-items-0 .navbar-nav,
  .Footer-wrapper .footer-let-talk-btn,
  .Footer-wrapper .LakeComm-footer-Address .heading-wrapper p,
  .logo-wrpapper .footer-img-wrapper {
    margin: auto;
  }
  .logo-wrpapper .footer-img-wrapper {
    max-width: 200px;
  }
}

.LB{
  color: #005082;
}